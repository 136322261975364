import React, { useContext } from 'react';
import { Header, Stream, Demo, Benefits, Cases, Carousel, SEO } from 'components';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ThemeContext } from 'context';

const Section = ({ className, bg, children }) => {
  return (
    <StyledSection className={className} bg={bg}>
      {children}
    </StyledSection>
  );
};

const Home = () => {
  const { isMobile, headerRef, aboutRef, benefitsRef, modulesRef, casesRef } = useContext(
    ThemeContext
  );
  return (
    <>
      <SEO pageSpecificTitle="Virtual Events" />
      <div id="header" ref={headerRef} />
      <Header />
      <Section>
        <div className="container">
          <div className="grid">
            <Pitch>
              Re-imagine your events as online shows, your customers as your audience, and their
              attention as your competitive advantage.
            </Pitch>
          </div>
        </div>
      </Section>
      <Section className="video">
        <div className="container">
          <div className="grid">
            <VideoContainer>
              {!isMobile && <p>watch our introduction video below</p>}
              <Stream url="https://vimeo.com/468875189" />
            </VideoContainer>
          </div>
        </div>
      </Section>
      <Section bg="rgba(239, 237, 228, 0.3)" className="about">
        <div id="about" ref={aboutRef} style={{ position: 'absolute', top: '-50px' }} />

        <div className="container">
          <div className="grid">
            <h3>About Event X</h3>
            <p>Event X, virtual events created by our specialist team at Agency X.</p>
            <p>
              Combining over 20 years of broadcast production expertise with the latest in digital marketing engagement technologies, Event X is here to create unique online event experiences
              for your business.
            </p>
            <p>
              From audience awareness pre-event, through to customised event website registration and live stream interactive show production, Event X virtual events transforms the way you
              communicate. Designed specifically for internal conferences, external market launch and online trade events.
            </p>
            <p>
              <strong>Event X - Virtual Events to Inspire, educate and engage the online world.</strong>
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div id="demo" style={{ position: 'absolute', top: '-18rem' }} />
        <div className="container">
          <div className="grid">
            <Demo />
          </div>
        </div>
      </Section>
      <Section className="modules">
        <div id="modules" ref={modulesRef} style={{ position: 'absolute', top: '-6rem' }} />
        <div className="container" style={{ overflowX: 'hidden' }}>
          <div className="grid">
            <ModulesTitle>How It Works</ModulesTitle>
            <ModulesSubtitle>
              Build your virtual events with modules,
              <br /> fully customisable to your needs
            </ModulesSubtitle>
            <Carousel />
          </div>
        </div>
      </Section>
      <Section className="benefits" bg="rgba(239, 237, 228, 0.3)">
        <div id="benefits" ref={benefitsRef} style={{ position: 'absolute', top: '-4rem' }} />
        <div className="container">
          <div className="grid">
            <h3>Benefits</h3>

            <Benefits />
            <SubText>
              The market conditions have changed for everyone. Remote working is on the rise, face to face meetings in decline. Virtual events online are key to customer collaboration and how
              well we will compete in this new, changing environment.
            </SubText>
          </div>
        </div>
      </Section>
      <Section className="cases">
        <div id="cases" ref={casesRef} style={{ position: 'absolute', top: '-3rem' }} />
        <div className="container">
          <div className="grid">
            <h3>Case Studies</h3>
            <p>
              Please find some client reference virtual events that are public facing in our case study links below. Each Event X online project is customised to the specific requirements of
              our clients and we will be delighted to share our experiences of best practise for your virtual event when ready. These might give you some ideas.
            </p>
            <Cases />
          </div>
        </div>
      </Section>
    </>
  );
};

export default Home;

const VideoContainer = styled.div`
  grid-column: 1/6;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    margin-bottom: 4rem;
  }
  p {
    text-transform: capitalize;
    color: black;
    font-size: 1.25rem;
    line-height: 1.25em;
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const Pitch = styled(motion.h3)`
  font-size: 1.5rem;
  line-height: 1.25em;
  max-width: 750px;
  margin: 3rem auto;
  grid-column: 1/6;
  transition-duration: 0.75s;
  @media (min-width: 768px) and (max-width: 1150px) {
    margin: 0 auto;
    margin-bottom: -3rem;
  }

  @media (min-width: 1150px) {
    grid-column: 3/11;
    margin: 1rem auto;
    margin-bottom: -1rem;
  }
`;

const StyledSection = styled(motion.section)`
  background: ${({ bg }) => bg};
  width: 100%;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition-duration: 1s;
  @media (min-width: 600px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 2rem;
  }
  &.video {
    padding: 0.625rem 0;
    @media (min-width: 768px) {
      padding: 4rem;
    }
    @media (min-width: 1150px) {
      padding: 2rem;
    }
  }
  &.about {
    h3 {
      font-size: 1.5rem;
      line-height: 1.25em;
      margin-bottom: 2em;
      text-transform: uppercase;
      grid-column: 1/6;
      @media (min-width: 1150px) {
        margin-bottom: 2em;

        grid-column: 3/11;
      }
    }
    p {
      font-size: 1.125rem;
      line-height: 1.3em;
      margin-bottom: 1.5em;
      color: black;
      grid-column: 1/6;
      text-align: center;
      @media (min-width: 1150px) {
        grid-column: 3/11;

        font-size: 1.25rem;
        line-height: 1.5em;
      }
    }
    transition-duration: 0.75s;
    padding: 3rem 0.625rem;
    padding-bottom: 13rem;
    @media (min-width: 600px) {
      padding: 4rem;
      padding-bottom: 10rem;
    }
    @media (min-width: 1150px) {
      padding: 4rem 2rem;
      padding-bottom: 13rem;
    }
  }

  &.cases {
    transition-duration: 0.75s;
    padding: 3rem 0.625rem;
    @media (min-width: 600px) {
      padding: 4rem;
    }
    @media (min-width: 1150px) {
      padding: 4rem 2rem;
      padding-bottom: 8rem;
    }
    h3 {
      font-size: 1.5rem;
      line-height: 1.25em;
      margin-bottom: 1.5em;
      text-transform: uppercase;
      grid-column: 1/6;
      @media (min-width: 1150px) {
        grid-column: 3/11;
        font-size: 2rem;
        line-height: 1.25em;
        margin-bottom: 1em;
      }
    }
    p {
      font-size: 1.125rem;
      line-height: 1.3em;
      margin-bottom: 3em;
      color: black;
      grid-column: 1/6;
      text-align: center;
      @media (min-width: 1150px) {
        grid-column: 3/11;
        font-size: 1.25rem;
        line-height: 1.5em;
      }
    }
  }

  &.benefits {
    transition-duration: 0.75s;
    padding: 3rem 0.625rem;
    @media (min-width: 600px) {
      padding: 4rem;
    }
    @media (min-width: 1150px) {
      padding: 4rem 2rem;
    }
    h3 {
      margin-bottom: 1.25rem;
      text-transform: uppercase;
      grid-column: 1/6;
      @media (min-width: 768px) {
        margin-bottom: 2rem;
      }
      @media (min-width: 1150px) {
        grid-column: 3/11;
      }
    }
  }
  &.modules {
    padding: 3rem 0.625rem;
    padding-top: 0;
    @media (min-width: 600px) {
      padding: 0 4rem;
      padding-bottom: 4rem;
      margin-top: -3rem;
    }
    @media (min-width: 1150px) {
      padding: 0rem 2rem;
      padding-bottom: 4rem;
    }
  }
`;

const SubText = styled.p`
  grid-column: 1/6;
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: black;
  text-align: center;
  @media (min-width: 1150px) {
    grid-column: 3/11;
  }
`;

const ModulesTitle = styled.h3`
  color: black;
  font-size: 3rem;
  line-height: 1em;
  margin-bottom: 0;
  letter-spacing: 0px;
  grid-column: 1/6;
  @media (min-width: 1150px) {
    grid-column: 5/9;
  }
`;

const ModulesSubtitle = styled.h5`
  grid-column: 1/6;
  font-size: 1.5rem;
  line-height: 1.25em;
  letter-spacing: 0px;
  color: var(--ex-main);
  margin-bottom: 2rem;

  @media (min-width: 1150px) {
    grid-column: 4/10;
  }
`;
